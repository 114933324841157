<template>
  <div>
    <IntroStandard :title="'Resources'" :content="introContent" />
    <ListOfLinks :resources="resourcesCollection" />
  </div>
</template>

<script>
import ListOfLinks from "@/components/ListOfLinks.vue";
import IntroStandard from "@/components/IntroStandard.vue";
import jsonContentResources from "@/components/componentsData/resources.json";

export default {
  components: {
    ListOfLinks,
    IntroStandard,
  },
  data() {
    return {
      resourcesCollection: jsonContentResources["resources"],
      introContent: jsonContentResources["introContent"],
    };
  },
};
</script>

<style></style>
