<template>
  <v-container class="justify-center">
    <h1 class="ml-4 mt-2">{{ titleName }}</h1>
    <v-divider class="ml-4 mt-2 mb-2"></v-divider>
  </v-container>
</template>

<script>
export default {
  name: "Title",
  props: {
    titleName: String,
  },
};
</script>

<style></style>
