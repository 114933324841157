<template>
  <div>
    <IntroStandard :title="'Contact'" :content="introContent" />

    <v-container v-if="displaySuccess">
      <v-alert outlined type="success" text>
        Thank you for reaching out! I'll try to get back to you as soon as
        possible!
      </v-alert>
    </v-container>

    <v-container v-if="displayError">
      <v-alert outlined type="error" text> Something went wrong... </v-alert>
    </v-container>

    <!-- Contact Form -->
    <v-container>
      <div class="ml-4 m4-4 mt-2 mr-4 mb-2">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            :counter="25"
            :rules="nameRules"
            label="Name"
            required
          ></v-text-field>

          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>

          <v-textarea
            v-model="message"
            :rules="messageRules"
            label="Message"
            value="Please type message here..."
            required
          >
          </v-textarea>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submitForm"
          >
            Submit
          </v-btn>
        </v-form>
      </div>
    </v-container>
    <!-- Contact Form End -->
  </div>
</template>

<script>
import jsonContentContact from "@/components/componentsData/contact.json";
import IntroStandard from "@/components/IntroStandard.vue";
import axios from "axios";

export default {
  components: { IntroStandard },
  data() {
    return {
      introContent: jsonContentContact["introContent"],
      endpoint: "https://formspree.io/f/mwkarera",
      valid: true,
      message: "",
      messageRules: [
        (v) => !!v || "Message is required",
        (v) =>
          (v && v.length >= 10) || "Message must be at least 10 characters",
      ],
      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 25) || "Name must be less than 25 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      displaySuccess: false,
      displayError: false,
    };
  },
  methods: {
    async submitForm() {
      const data = {
        name: this.name,
        email: this.email,
        message: this.message,
      };
      await axios
        .post(this.endpoint, data)
        .then(() => {
          this.displayError = false;
          this.displaySuccess = true;
          this.reset();
        })
        .catch(() => {
          this.displaySuccess = false;
          this.displayError = true;
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
