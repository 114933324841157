<template>
  <div>
    <BaseTitle titleName="Evidence" />
    <div v-if="firstPage">
      <BaseIntroText :introContent="introContent" />
      <BaseDivider />
    </div>

    <!-- Evidence Cards -->
    <v-container>
      <v-row>
        <div v-for="item in displayedCards" :key="item.counterSummary">
          <v-col>
            <EvidenceCard
              :belief="item.belief"
              :counterSummary="item.counterSummary"
              :counterDescription="item.counterDescription"
              :resources="item.citationsCollection"
            />
          </v-col>
        </div>
      </v-row>
    </v-container>

    <!-- Buttons for pagination (just 2 pages) -->
    <v-card flat class="mb-4">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <h3 v-if="firstPage" class="text-center font-weight-bold">1/2</h3>
            <h3 v-else class="text-center font-weight-bold">2/2</h3>
          </v-col>
          <v-btn color="primary" v-if="firstPage" @click="currentPageChange"
            ><v-icon>mdi-arrow-right-bold</v-icon></v-btn
          >
          <v-btn color="primary" v-else @click="currentPageChange"
            ><v-icon>mdi-arrow-left-bold</v-icon></v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import EvidenceCard from "@/components/EvidenceCard.vue";
import jsonContentEvidence from "@/components/componentsData/evidence.json";
import Vue from "vue";

export default {
  components: {
    EvidenceCard,
  },
  data() {
    return {
      evidenceList: jsonContentEvidence["evidence"],
      introContent: jsonContentEvidence["introContent"],
      firstPage: true,
    };
  },
  computed: {
    displayedCards: function () {
      if (this.firstPage) {
        return this.evidenceList.slice(0, 10);
      } else {
        return this.evidenceList.slice(10, 15);
      }
    },
  },
  methods: {
    currentPageChange: function () {
      this.firstPage = !this.firstPage;
      if (!this.firstPage) {
        Vue.prototype.$scrollToTop();
      }
    },
  },
};
</script>

<style></style>
