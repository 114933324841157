<template>
  <v-container>
    <div class="ml-4 m4-4 mt-2 mr-4 mb-2">
      <p v-for="(paragraph, index) in introContent" :key="index">
        {{ paragraph }}
      </p>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "IntroText",
  props: {
    introContent: Array,
  },
};
</script>

<style></style>
