<template>
  <v-footer padless>
    <v-col class="text-center secondary--text" cols="12">
      {{ new Date().getFullYear() }} — Built With
      <v-icon color="red">mdi-heart</v-icon> — <strong>Golem</strong>
      <v-btn
        plain
        class="ml-4 mb-1"
        color="black"
        v-on="on"
        @click="darkMode"
        v-if="!$vuetify.theme.dark"
        >Dark Theme<v-icon color="black" class="mr-1"
          >mdi-moon-waxing-crescent</v-icon
        ></v-btn
      >
      <v-btn
        plain
        class="ml-4 mb-1"
        color="secondary"
        v-on="on"
        @click="darkMode"
        v-else
        >Light Theme<v-icon class="ml-1" color="yellow"
          >mdi-white-balance-sunny</v-icon
        ></v-btn
      >
    </v-col>
    <div></div>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>

<style></style>
