<template>
  <div>
    <v-container>
      <v-layout justify-center align-center>
        <h1 class="mt-2 mb-2">
          <v-img
            lazy-src="@/assets/logo.png"
            max-height="150"
            max-width="250"
            src="@/assets/logo.png"
          ></v-img>
        </h1>
      </v-layout>
      <v-divider class="ml-4 mt-2 mb-2"></v-divider>
    </v-container>

    <BaseIntroText :introContent="intro" />
    <BaseDivider />

    <!-- Row of Buttons -->

    <!-- For mobile -->
    <v-content class="hidden-md-and-up">
      <v-container>
        <v-layout align-center justify-center>
          <v-btn :to="'/about'" class="ml-2 mr-2" color="secondary" x-large>
            What is this site?
          </v-btn>
        </v-layout>
      </v-container>
      <v-container>
        <v-layout align-center justify-center>
          <v-btn :to="'/evidence'" class="ml-2 mr-2" color="primary" x-large>
            Why do I think ME/CFS is TMS?
          </v-btn>
        </v-layout>
      </v-container>
      <v-container>
        <v-layout align-center justify-center>
          <v-btn
            :to="'/recoveryguide'"
            class="ml-2 mr-2"
            color="secondary"
            x-large
          >
            Recovery Guide
          </v-btn>
        </v-layout>
      </v-container>
    </v-content>

    <!-- For non-phone screens -->
    <v-content class="hidden-sm-and-down">
      <v-container>
        <v-layout align-center justify-center>
          <v-btn :to="'/about'" class="ml-2 mr-2" color="secondary" x-large>
            What is this site?
          </v-btn>
          <v-btn :to="'/evidence'" class="ml-2 mr-2" color="primary" x-large>
            Why do I think ME/CFS is TMS?
          </v-btn>
          <v-btn
            :to="'/recoveryguide'"
            class="ml-2 mr-2"
            color="secondary"
            x-large
          >
            Recovery Guide
          </v-btn>
        </v-layout>
      </v-container>
    </v-content>

    <!-- Sarno Youtube Embedded Video -->

    <v-container>
      <v-layout align-center justify-center>
        <div class="ml-4 m4-4 mt-2 mr-4 mb-2">
          <iframe
            :width="$vuetify.breakpoint.xs ? 336 : 560"
            :height="$vuetify.breakpoint.xs ? 189 : 315"
            src="https://www.youtube.com/embed/_625GCv-oQk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </v-layout>
    </v-container>

    <BaseDivider />

    <!-- Disclaimer -->

    <v-container>
      <div class="ml-4 mt-0 mr-4 mb-2 text-center text--error">
        <h3 class="error--text">Disclaimer!</h3>
        <p>{{ disclaimer }}</p>
      </div>
    </v-container>
  </div>
</template>

<script>
import jsonContentHomeText from "@/components/componentsData/home.json";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      introText: jsonContentHomeText["introContent"],
    };
  },
  computed: {
    intro: function () {
      return this.introText.slice(0, 1);
    },
    disclaimer: function () {
      return this.introText.slice(1, 2)[0];
    },
  },
};
</script>
