<template>
  <div>
    <BaseTitle :titleName="'The What and Why Behind This Site'" />
    <v-container>
      <div class="ml-4 mr-4">
        <v-card elevation="0">
          <v-card-title
            class="justify-center text-decoration-underline secondary--text"
            >My Story</v-card-title
          >
          <v-card-text>
            <p v-for="(paragraph, index) in myStoryContent" :key="index">
              {{ paragraph }}
            </p>
          </v-card-text>
        </v-card>
        <v-card elevation="0">
          <v-card-title
            class="justify-center text-decoration-underline secondary--text"
            >Why this site?</v-card-title
          >
          <v-card-text>
            <p v-for="(paragraph, index) in siteExplanation" :key="index">
              {{ paragraph }}
            </p>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import jsonContent from "@/components/componentsData/about.json";

export default {
  components: {},
  data() {
    return {
      introContent: jsonContent["aboutContent"],
    };
  },
  computed: {
    myStoryContent() {
      return this.introContent.slice(0, 3);
    },
    siteExplanation() {
      return this.introContent.slice(3, 7);
    },
  },
};
</script>
