<template>
  <div>
    <BaseTitle titleName="404! Page not found!" />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
