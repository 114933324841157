<template>
  <div>
    <IntroStandard :title="'Success Stories'" :content="introContent" />
    <ListOfLinks :resources="storiesCollection" />
  </div>
</template>

<script>
import ListOfLinks from "@/components/ListOfLinks.vue";
import IntroStandard from "@/components/IntroStandard.vue";
import jsonContentSuccesses from "@/components/componentsData/successes.json";

export default {
  components: {
    ListOfLinks,
    IntroStandard,
  },
  data() {
    return {
      storiesCollection: jsonContentSuccesses["successes"],
      introContent: jsonContentSuccesses["introContent"],
    };
  },
};
</script>

<style>
/* unvisited link */
a:link {
  text-decoration: none;
}

/* visited link */
a:visited {
  text-decoration: none;
}

/* mouse over link */
a:hover {
  text-decoration: none;
}

/* selected link */
a:active {
  text-decoration: none;
}
</style>
