<template>
  <div>
    <IntroStandard :title="'Recovery Guide'" :content="introContent" />

    <!-- The Steps -->
    <v-container>
      <div v-for="(step, index) in recoverySteps" :key="index">
        <RecoveryGuideCard
          :stepNumber="index + 1"
          :title="step.title"
          :body="step.body"
          :icon="step.icon"
          :links="step.links"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import RecoveryGuideCard from "@/components/RecoveryGuideCard.vue";
import jsonContentRecovery from "@/components/componentsData/recovery.json";
import IntroStandard from "@/components/IntroStandard.vue";

export default {
  components: {
    RecoveryGuideCard,
    IntroStandard,
  },
  data() {
    return {
      recoverySteps: jsonContentRecovery["steps"],
      introContent: jsonContentRecovery["intro"],
    };
  },
};
</script>
