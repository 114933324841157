<template>
  <div>
    <v-card>
      <v-app-bar>
        <router-link to="/" class="mb-2">
          <v-img
            lazy-src="@/assets/logo.png"
            max-height="50"
            max-width="84"
            src="@/assets/logo.png"
          ></v-img>
        </router-link>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-flex v-for="page in directoryLeft" :key="page.title" class="mt-3">
            <v-btn color="primary" plain :to="page.path">
              {{ page.title }}
            </v-btn>
          </v-flex>
        </v-toolbar-items>

        <!-- Aligns about link right -->
        <v-spacer></v-spacer>

        <!-- Right Links -->
        <div class="mb-3">
          <v-toolbar-items class="hidden-sm-and-down">
            <v-flex
              v-for="page in directoryRight"
              :key="page.title"
              class="mt-3"
            >
              <v-btn color="primary" plain :to="page.path">
                {{ page.title }}
              </v-btn>
            </v-flex>
          </v-toolbar-items>
        </div>

        <!-- Burger Button -->
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
          lock
          class="hidden-md-and-up"
        ></v-app-bar-nav-icon>
      </v-app-bar>
    </v-card>

    <!-- Burger Button Pages -->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      right
      temporary
      colors="green"
      dense
      dark
    >
      <v-list v-for="page in directoryAll" :key="page.title" nav dense>
        <v-list-item plain :key="page.title" :to="page.path">
          <v-list-item-content>
            <v-list-item-title>{{ page.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Allows you to see other views -->
    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  data() {
    return {
      directoryLeft: [
        { title: "Recovery Guide", path: "/recoveryguide", type: "internal" },
        { title: "Evidence", path: "/evidence", type: "internal" },
        { title: "Resources", path: "/resources", type: "internal" },
        { title: "Success Stories", path: "/successstories", type: "internal" },
      ],
      directoryRight: [
        { title: "Contact", path: "/contact", type: "internal" },
        { title: "About", path: "/about", type: "internal" },
      ],
      drawer: false,
    };
  },
  computed: {
    directoryAll() {
      const allLinks = this.directoryLeft.concat(this.directoryRight);
      return allLinks;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
