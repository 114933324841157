import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";

Vue.config.productionTip = false;

Vue.prototype.$scrollToTop = function () {
  var element = document.getElementById("topDiv");
  var top = element.offsetTop;
  window.scrollTo(0, top);
};

const requireComponent = require.context(
  // Look for files in the components/BaseUI directory
  "./components/BaseUI",
  // Do not look in subdirectories
  true,
  // Only include "_base-" prefixed .vue files
  /_base-[\w-]+\.vue$/
);

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName);
  // Get the PascalCase version of the component name
  const componentName = fileName
    // Remove the "./_" from the beginning
    .replace(/^\.\/_/, "")
    // Remove the file extension from the end
    .replace(/\.\w+$/, "")
    // Split up kebabs
    .split("-")
    // Upper case
    .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
    // Concatenated
    .join("");

  // Globally register the component
  console.log(componentName);
  Vue.component(componentName, componentConfig.default || componentConfig);
});

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
