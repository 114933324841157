<template>
  <div class="ml-4 mt-2 mr-4 mb-2">
    <v-card elevation="1" class="mx-auto">
      <v-card-title
        ><v-icon left color="secondary">{{ icon }}</v-icon> {{ title }}
      </v-card-title>
      <v-card-text>{{ body }}</v-card-text>

      <!-- If there are links, then we need v-card-actions to display them -->
      <div v-if="links">
        <!-- If we are on a medium or larger screen, display links side by side -->
        <v-card-actions class="hidden-sm-and-down">
          <!-- External Links -->
          <div v-for="(link, index) in links" :key="index">
            <v-btn
              v-if="link.type === 'external'"
              :href="link.path"
              target="_blank"
              text
              color="primary"
            >
              {{ link.title }}
            </v-btn>

            <!-- Internal Links -->
            <v-btn
              v-if="link.type === 'internal'"
              :to="link.path"
              text
              color="primary"
            >
              {{ link.title }}
            </v-btn>
          </div>
        </v-card-actions>

        <!-- If we are on a small screen, display links stacked on top of one another -->
        <v-card-actions
          v-for="(link, index) in links"
          :key="index"
          class="hidden-md-and-up"
        >
          <v-btn
            v-if="link.type === 'external'"
            :href="link.path"
            target="_blank"
            text
            color="primary"
          >
            {{ link.title }}
          </v-btn>

          <!-- Internal Links -->
          <v-btn
            v-if="link.type === 'internal'"
            :to="link.path"
            text
            color="primary"
          >
            {{ link.title }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "RecoveryGuideCard",
  props: {
    stepNumber: Number,
    title: String,
    body: String,
    icon: String,
    links: Array,
  },
};
</script>

<style></style>
