<template>
  <div class="ml-4 mt-0 mr-4 mb-0">
    <v-card elevation="1">
      <v-card-title>
        <v-icon left color="secondary">mdi-head-question-outline</v-icon
        >{{ belief }}</v-card-title
      >
      <v-card-subtitle>
        <v-icon left color="success">mdi-check-circle-outline</v-icon>
        {{ counterSummary }}</v-card-subtitle
      >
      <v-divider class="mr-2 ml-2"></v-divider>
      <v-card-text v-if="showExplanation">{{ counterDescription }}</v-card-text>
      <v-card-actions>
        <v-btn
          v-if="!showExplanation"
          text
          color="primary"
          @click="showExplanation = true"
          >Expand</v-btn
        >
        <v-btn v-else text color="primary" @click="showExplanation = false"
          >Collapse</v-btn
        >
        <v-btn
          v-if="resources"
          text
          color="primary"
          @click="
            showExplanation = true;
            reveal = true;
          "
          >Citations</v-btn
        >
      </v-card-actions>

      <v-expand-transition>
        <v-card
          v-if="reveal"
          class="transition-fast-in-fast-out v-card--reveal"
          style="height: 100%"
        >
          <!-- Revealed Card Content -->
          <ListOfLinks :resources="resources" />

          <v-card-actions class="on-the-bottom">
            <v-btn text color="primary" @click="reveal = false"
              >Back To Summary</v-btn
            >
          </v-card-actions>
        </v-card>
        <!-- End Revealed Card Content -->
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import ListOfLinks from "@/components/ListOfLinks.vue";

export default {
  name: "EvidenceCard",
  components: {
    ListOfLinks,
  },
  props: {
    belief: String,
    counterSummary: String,
    counterDescription: String,
    resources: Array,
  },
  data() {
    return {
      reveal: false,
      showExplanation: false,
    };
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.on-the-bottom {
  position: absolute;
  bottom: 0;
}
</style>
