<template>
  <div>
    <BaseTitle :titleName="title" />
    <BaseIntroText :introContent="content" />
    <BaseDivider />
  </div>
</template>

<script>
export default {
  name: "IntroStandard",
  props: {
    title: String,
    content: Array,
  },
};
</script>

<style></style>
