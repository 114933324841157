<template>
  <v-container>
    <div class="ml-4 m4-4 mt-2 mr-4 mb-2">
      <v-divider></v-divider>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Divider",
};
</script>

<style></style>
