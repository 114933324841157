import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Evidence from "../views/Evidence.vue";
import RecoveryGuide from "../views/RecoveryGuide.vue";
import SuccessStories from "../views/SuccessStories.vue";
import Resources from "../views/Resources.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import NotFound from "../components/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/evidence",
    name: "Evidence",
    component: Evidence,
  },
  {
    path: "/resources",
    name: "Resources",
    component: Resources,
  },
  {
    path: "/successstories",
    name: "SuccessStories",
    component: SuccessStories,
  },
  {
    path: "/recoveryguide",
    name: "RecoveryGuide",
    component: RecoveryGuide,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/:notFound(.*)",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
