<template>
  <v-container>
    <v-list>
      <v-list-item v-for="(resource, index) in resources" :key="index" two-line>
        <v-list-item-content>
          <v-list-item-title>
            <v-icon v-if="resource.media === 'video'" color="secondary"
              >mdi-video-outline</v-icon
            >
            <v-icon v-else-if="resource.media === 'book'" color="secondary"
              >mdi-book-open-page-variant-outline</v-icon
            >
            <v-icon
              v-else-if="resource.media === 'internetInfo'"
              color="secondary"
              >mdi-link</v-icon
            >
            <a :href="resource.link" target="_blank" text
              >{{ space }}{{ resource.title }}
            </a>
            <v-icon v-if="resource.paid" color="green">mdi-currency-usd</v-icon>
          </v-list-item-title>
          <v-list-item-subtitle>{{
            resource.description
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
export default {
  name: "ListOfLinks",
  props: {
    resources: Array,
  },
  data() {
    return {
      space: " ",
    };
  },
};
</script>

<style></style>
