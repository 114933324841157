<template>
  <v-app>
    <div id="topDiv"></div>
    <NavigationBar />
    <Footer absolute fixed />
  </v-app>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    NavigationBar,
    Footer,
  },
};
</script>
